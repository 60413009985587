const QRCode = require("qrcode");

const nameInputElement = document.getElementById("name");
const uidInputElement = document.getElementById("uid");
const oldUid = localStorage.getItem("uid");
const oldName = localStorage.getItem("name");

window.refreshQRCode = () => {
  const name = nameInputElement.innerText;
  const uid = uidInputElement.innerText;
  localStorage.setItem("name", name);
  localStorage.setItem("uid", uid);
  const now = new Date().getTime().toString();
  const url = `http://ehallapp.nju.edu.cn/qljfwapp/sys/lwWiseduElectronicPass/w/${uid}.do?time=${now.slice(
    0,
    -3
  )}000`;
  console.log(url);
  const opts = {
    type: "image/jpeg",
    color: {
      dark: "#842686",
      light: "#FFFFFF",
    },
  };

  QRCode.toDataURL(url, opts, function (err, url) {
    if (err) throw err;
    const img = document.getElementById("qr-code");
    img.src = url;
  });
};

if (oldUid) {
  nameInputElement.innerText = oldName;
  uidInputElement.innerText = oldUid;
  window.refreshQRCode();
} else {
  window.alert("请点击姓名和工号修改，完成后点击刷新生效");
}

console.log(document.getElementById("background").width * 2);

function adjust_position() {
  let background_height = document.getElementById("background").width * 2.09;
  let qr_code_width = background_height * 0.248;
  let qr_code_top = background_height * 0.58;
  let qr_code_left = background_height * 0.115;
  let code_input_top = background_height * 0.385;
  let code_input_left = background_height * 0.305;
  let code_input_size = background_height * 0.02;
  let refresh_btn_top = background_height * 0.853;
  let refresh_btn_left = background_height * 0.305;
  let name_top = background_height * 0.205;
  let name_left = background_height * 0.26;

  // 1.二维码位置
  let qr_code_box = document.getElementById("qr-box");
  let qr_code = document.getElementById("qr-code");
  qr_code_box.style.cssText += `top: ${qr_code_top}px`;
  qr_code_box.style.cssText += `left: ${qr_code_left}px`;
  qr_code.style.cssText += `width: ${qr_code_width}px`;
  console.log(background_height);
  // 2.输入框位置
  let code_input = document.getElementById("uid");
  code_input.style.cssText += `top: ${code_input_top}px`;
  code_input.style.cssText += `left: ${code_input_left}px`;
  code_input.style.cssText += `font-size: ${code_input_size}px`;
  // 3.刷新按钮位置
  let refresh_btn = document.getElementById("refresh-btn");
  refresh_btn.style.cssText += `top: ${refresh_btn_top}px`;
  refresh_btn.style.cssText += `left: ${refresh_btn_left}px`;
  // 4.姓名位置
  let name = document.getElementById("name");
  name.style.cssText += `top: ${name_top}px`;
  name.style.cssText += `left: ${name_left}px`;
}
adjust_position();
